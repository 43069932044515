import { PureComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';


class InvitationU18 extends PureComponent
{
	render()
	{
		return (
			<Container fluid="lg" className="my-4">

				<Card border="secondary" className="my-3">
					<Card.Header as="h5">
						International Masters Bremen 2024 &mdash; Men U18
					</Card.Header>
					<Card.Body>

						<table className="table">
							<tbody>

								<tr>
									<th scope="row">
										Promotor / Organisation
									</th>
									<td>
										Bremen Judo-Club (member of the Bremen and German Judo Federation)
									</td>
								</tr>

								<tr>
									<th scope="row">
										Venue
									</th>
									<td>
										Congress Centre Bremen, Exhibition Hall 7 (Messehalle 7), Hollerallee/Findorffstr. (behind Bremen Central Station)
									</td>
								</tr>

								<tr>
									<th scope="row">
										Date of tournament
									</th>
									<td>
										Sat, 23 March 2024 (official weigh-in also on 22 March)
									</td>
								</tr>

								<tr>
									<th scope="row">
										Eligibility
									</th>
									<td>
										Boys born in 2007-2009 (IJF-membership)
									</td>
								</tr>

								<tr>
									<th scope="row">
										Categories
									</th>
									<td>
										-50kg, -55kg, -60kg, -66kg, -73kg, -81kg, -90kg, +90kg
									</td>
								</tr>

								<tr>
									<th scope="row">
										System
									</th>
									<td>
										KO-System with double Repechage
									</td>
								</tr>

								<tr>
									<th scope="row">
										Schedule
									</th>
									<td>
										{/* Not decided yet. Check back for updates. */}
										<table className="table">
											<tbody>
												<tr>
													<td>Fri, 22 March 2024</td><td>17:00-19:30</td><td>official check- and weigh-in for all categories</td>
												</tr>
												<tr>
													<td rowSpan={4}>Sat, 23 March 2024</td><td>07:45-08:15</td><td>official check- and weigh-in for all categories</td>
												</tr>
												<tr>
													{/* <td>09:00</td><td>Start of contests.<br/> Fighting order will be decided about a week before the tournament. Check back for updates.</td> */}
													<td>09:00</td><td>Start of contests: -55kg, -60kg, -66kg, -73kg</td>
												</tr>
												<tr>
													<td>not before 11:00</td><td>Start of contests: -50kg, -81kg</td>
												</tr>
												<tr>
													<td>not before 12:00</td><td>Start of contests: -90kg, +90kg</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>

								<tr>
									<th scope="row">
										Registration
									</th>
									<td>
										For the registration, please, use the online registration system.<br/>
										We will open the online registration around 15 January 2024.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Registration fee
									</th>
									<td>
										The registration fee will be 80 EUR per registered Judoka.<br/>
										The registration fee after 15 March 2024 will be 110 EUR.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Referees
									</th>
									<td>
										Will be invited by the promotor/organiser. However, all international
										Judo-Clubs or Associations may bring (at their own expenses) licensed
										referees of international or high national standard. If you do so, please,
										inform us in advance.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Liability / Insurance
									</th>
									<td>
										The promotor/organiser reject all responsibility and liability for all kind
										of accidents and thefts in connection with this event. If not agreed
										otherwise accident- and liability-insurance have to be taken care of by
										the participants (their clubs or federations) themselves.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Others
									</th>
									<td>
										With the registration to the International Masters Bremen 2024 each Judoka gives his permission to the digital storage of his competition-related data and the publishment of the same in bulletins,
										the internet and other means of publication.
										The same applies for photographs, videos and other means of photographic documentation.
										White and blue Judogi are obligatory. Judogi without the official "IJF-approved"-label are also allowed as long as they are in accordance with the general rules (Sokuteiki control).
										However, official back-patches are not required.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Contact address
									</th>
									<td>
										Norbert Specker<br />
										Parkallee 89<br />
										28209 Bremen<br />
										Germany<br />
										phone: +49-160-97515414<br />
										e-mail: <a href="mailto:norbert.specker@specker-bremen.de">norbert.specker@specker-bremen.de</a>
									</td>
								</tr>

							</tbody>
						</table>

					</Card.Body>
				</Card>

			</Container>
		);
	}
}


export { InvitationU18 };
